<section class="content">
    <div class="container-fluid">


        <div class="header-container">
            <div class="spacer"></div>
            <div class="header"> HOW TO INSTALL TEST CREDIT CODES </div>
            <img class="logo" src="assets/mes_logo.png" alt="MES-Logo"/>    
        </div>
   

        <div class="text"> Click on the <strong>TEST CREDIT CODE INSTALLATION</strong> button- </div>        
        <a href="https://storage.googleapis.com/testcreditcode-sqa-io/Installation/TestCreditLoader_Setup.exe" target="_blank">
            <img src="assets/download_button.png" alt="Download Test Credit Loader" class="download-btn" />
        </a>
        
        <div class="text mb-1"> Then click on the downloaded "TestCreditLoader_Setup" file to initiate the process. </div>
        <div class="text mt-1"> Follow the directions below to install the
            <strong> TEST CREDIT CODE SOFTWARE </strong> and load your credits.
        </div>
        

        <div class="box-container">

            <div class="box">
                <img class="step" src="assets/step1.png" alt="step-1" />
                <div class="box-text"> Connect the SQA device to a PC that is <strong> connected to the internet! </strong> </div>
            </div>

            <div class="box">
                <img class="step" src="assets/step2.png" alt="step-2" />
                <div class="box-text"> Type in the unique <strong> TEST CREDIT CODE </strong> found on the inside cover of the SQA Capillary Box.
                </div>
            </div>

            <div class="box">
                <img class="step" src="assets/step3.png" alt="step-3" />
                <div class="box-text"> The software will check and verify the <strong> COM PORT </strong> - it will let you know if you need to
                    select a different port. </div>
            </div>

            <div class="box">
                <img class="step" src="assets/step4.png" alt="step-4" />
                <div class="box-text"> The <strong> TEST CREDITS </strong> will be loaded into the device in around one minute. </div>
                <img class="clock" src="assets/clock.png" alt="clock" />
                <div class="box-text"> The code is no longer valid. </div>
            </div>

        </div>


    </div>
</section>